import React from 'react';

import { CoreFeatures } from './CoreFeatures/CoreFeatures';
import { HomeHeader } from './HomeHeader/HomeHeader';
import { HomeReviews } from './HomeReviews/HomeReviews';
import { NetworkStats } from './NetworkStats/NetworkStats';
import { CtaPanel } from '@components/CtaPanel/CtaPanel';
import { Head } from '@components/Layout/Head';
import { routes } from '@routes';
import { cdnStats } from '@siteData';

interface IProps {
  title?: string;
}

export const Home: React.FC<IProps> = ({ title }) => (
  <>
    <Head
      canonical={routes.homePage}
      description={`Experience unmatched CDN performance at the best prices, powered by ${cdnStats.capacity.network} network across ${cdnStats.continentsCount} continents. Start testing with us today!`}
      title={title ?? 'Content Delivery Network (CDN)'}
    />
    <HomeHeader />
    <CoreFeatures />
    <NetworkStats />
    <HomeReviews />
    <CtaPanel className="mb-5" />
  </>
);
