'use client';

import classNames from 'classnames/bind';
import React from 'react';

import { CtaPanelWrapper } from './CtaPanelWrapper/CtaPanelWrapper';
import { Button } from '@components/Button/Button';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { routes } from '@routes';

import styles from './CtaPanel.module.scss';

type IButton = {
  id?: string;
  title: string;
} & (
  | {
      href: string;
      onClick?: never;
    }
  | { onClick: () => void; href?: never }
);
export interface Props extends React.ComponentProps<typeof CtaPanelWrapper> {
  title?: string | JSX.Element;
  text?: string;
  buttons?: IButton[];
}

const cx = classNames.bind(styles);

export const CtaPanel: React.FC<Props> = ({
  title = 'Deliver your content with CDN77',
  text,
  buttons = [{ title: 'Talk to sales', href: routes.getStarted, id: 'cta-contact' }],
  children,
}) => (
  <CtaPanelWrapper>
    <h2 className={cx('title', text ? 'mb-2' : 'mb-5')}>{title}</h2>
    {text && (
      <Paragraph align="center" className="mb-5" color="light" opacity={60} size="lg">
        {text}
      </Paragraph>
    )}
    {children}

    <div className={styles.buttonsWrapper}>
      {buttons.map(({ title, ...rest }, index) => (
        <Button
          key={typeof title === 'string' ? `${title}-${index}` : index}
          className={styles.button}
          theme="button"
          variant={index === 0 ? 'primary' : 'inverted'}
          {...rest}
        >
          {title}
        </Button>
      ))}
    </div>
  </CtaPanelWrapper>
);
